export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'branches',
      displayName: 'menu.branches',
      meta: {
        icon: 'apartment',
      },
    },
    {
      name: 'branchAdmins',
      displayName: 'menu.branchAdmins',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'teachers',
      displayName: 'menu.teachers',
      meta: {
        icon: 'co_present',
      },
    },
    {
      name: 'parents',
      displayName: 'menu.parents',
      meta: {
        icon: 'supervisor_account',
      },
    },
    {
      name: 'programmes',
      displayName: 'menu.programmes',
      meta: {
        icon: 'topic',
      },
    },
    {
      name: 'syllabuses',
      displayName: 'menu.syllabuses',
      meta: {
        icon: 'view_list',
      },
    },
    {
      name: 'students',
      displayName: 'menu.students',
      meta: {
        icon: 'person',
      },
    },
  ] as INavigationRoute[],
}
