import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyD6NAZfGqM5x78HHthBDw17RI_bhemu_FQ',
  authDomain: 'pine-tree-e304f.firebaseapp.com',
  projectId: 'pine-tree-e304f',
  storageBucket: 'pine-tree-e304f.appspot.com',
  messagingSenderId: '354617784766',
  appId: '1:354617784766:web:f18ef57580a63eb1bfa1ef',
  measurementId: 'G-WG3HPPJHV1',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { db, app }
